import React from 'react';
import { Link, graphql } from 'gatsby';
import Script from 'react-load-script';

// import { Layout } from '../components/Layout/Layout';
import { TitleAndMetaTags } from '../components/TitleAndMetaTags/TitleAndMetaTags';
import { Section } from '../components/Section/Section';
import { Product } from '../components/Product/Product';
import { Contact } from '../components/Contact/Contact';
import { Typewriter } from '../components/Typewriter/Typewriter';
import { Banner } from '../components/Banner/Banner';
import { FakeImg } from '../components/FakeImg/FakeImg';

import img1 from '../images/img-1.jpg';
import img2 from '../images/img-2.jpg';
import ritaImg from '../images/rita.jpg';
import bannerImg from '../images/banner.jpg';
import bannerMobileImg from '../images/banner-mobile.jpg';

function handleScriptLoad() {
	if (typeof window !== `undefined` && window.netlifyIdentity) {
		window.netlifyIdentity.on('init', user => {
			if (!user) {
				window.netlifyIdentity.on('login', () => {
					document.location.href = '/admin/';
				});
			}
		});
	}
	window.netlifyIdentity.init();
}

function IndexPage({ data, location }) {
	return (
		// <Layout location={location}>
		<div className="main">
			<Script
				url="https://identity.netlify.com/v1/netlify-identity-widget.js"
				onLoad={() => handleScriptLoad()}
			/>
			<TitleAndMetaTags />
			<Section>
				<div className="grid">
					<h1 className="col -block md-push-1 lg-push-2 title">
						Best Foods
						<br />
						Franchise.
						<span className="title-desc">Bestfoods </span>
						<Typewriter
							className="title-desc"
							texts={[
								'Supermarket.',
								'Fresh Vegetables.',
								'Fresh Fruits.',
								'Grocers.',
								'Wholesalers.',
								'goodness.'
							]}
						/>
					</h1>
				</div>

				<div className="grid">
					<div className="col md-7 lg-5 md-push-1 lg-push-2 lg-push-3">
						<p className="measure">
							Bestfoods started in 1989 to serve the Sri Lankan migrant community as a Sri
							Lankan shop with the experienced gained to satisfy many different customers’
							demands Bestfoods started opening Franchise General Supermarkets in London
							and Home counties. Today, 24 Bestfoods supermarkets are selling many popular
							business categories and gained in-depth knowledge and would like to invite
							interesting shop owners to expand their business by joining the successful
							Bestfoods Franchise.
						</p>
						<p className="measure">
							Try pairing it with a short black coffee São Paulo style, or spread thickly
							with guava<sup className="-yellow">&#9679;</sup> jam for a sweet contrast.
						</p>

						<p>
							<Link to="/story" className="button">
								Read our story
							</Link>
						</p>
					</div>

					<div className="col md-3 lg-2">
						<p className="small faded footnote -yellow" data-id="&#9679;">
							30 years experiences in Buying and Selling following categories; Sri Lankan,
							Indian, Afro-caribbean, General Groceries Off-Licence products Newspapers
							and magazines Fresh Meat Halal Meat Fresh Fish Frozen Fish Fresh fruit and
							vegetables etc.
						</p>
					</div>
				</div>

				<div className="grid">
					<div className="gallery fake-img-group">
						<div className="col fluid md-7 md-push-1 lg-6 lg-push-2 fake-img-wrapper">
							<FakeImg className="-three-x-two" img={img2} />
						</div>

						<div className="col fluid md-3 fake-img-wrapper">
							<FakeImg className="-two-x-three" img={ritaImg} />
						</div>
					</div>
				</div>
			</Section>

			<Section className="-blue">
				<div className="grid">
					<h1 className="col md-push-1 lg-push-2">Shop it</h1>
				</div>

				<div className="grid">
					<div className="col md-7 lg-5 md-push-1 lg-push-3">
						<h3 className="measure-wider">Find a shop</h3>
						<p className="measure-wider">
							Buy our fresh fish and meat from the shops around London.
						</p>
						<p className="measure-wider">
							<Link to="/stockists" className="button">
								See franchises
							</Link>
						</p>
					</div>

					{/* <div className="col md-3 lg-2">
		<h3>Find a shop</h3>
		<p className="small">Buy our frozen bake-at-home packs from a few shops around London.</p>
		<p style="margin-top: 30px;"><a href="/stockists" className="button">See stockists</a></p>
	</div> */}
				</div>

				<div className="grid">
					<div className="col fluid md-7 md-push-1 lg-6 lg-push-2">
						<FakeImg className="-three-x-two" img={img1} />
					</div>
				</div>

				<div className="grid">
					<div className="col md-7 lg-5 md-push-1 lg-push-2">
						<h2 className="h1">It's pronounced Bestfoods fresh fish.</h2>
					</div>
				</div>
			</Section>

			<Product
				postcodes={data.allPostcodesJson.edges[0].node.allowed}
				stockists={data.allMarkdownRemark.edges}
				location={location}
				soldout
			/>

			<Banner img={bannerImg} mobileImg={bannerMobileImg} />

			<Section className="-salmon testimonial">
				<div className="grid">
					<blockquote className="col md-9 md-push-1 lg-push-2">
						<h3 className="h1">
							A crunchy outside giving way to a cloud-like interior. Sounds ace, right?
							Right.
						</h3>
						<p>Tom Howells – TimeOut London</p>
					</blockquote>
				</div>
			</Section>

			<Section>
				<Contact />
			</Section>
		</div>
		// </Layout>
	);
}

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
	query IndexPageQuery {
		allPostcodesJson {
			edges {
				node {
					allowed
				}
			}
		}
		allMarkdownRemark {
			edges {
				node {
					frontmatter {
						name
						address
						postcode
						outcode
					}
				}
			}
		}
	}
`;

export default IndexPage;
